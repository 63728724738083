<template>
  <div class="error-container">
    <HeaderMenu/>
    <div class="content">
      <p class="tip">Sorry，页面走丢了哦～</p>
      <div class="img-404"></div>
      <div class="back">
        <a-link class="text" href="/">返回首页</a-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICountry } from './@types/entities';
type IHotCountryList = ICountry & { id: string, priority: number }

const countryList = ref<IHotCountryList[]>([]);
async function loadCountryList() {
  const res = await fetch('/api/resource/country/hot');
  const { data } = await res.json() as { data: IHotCountryList[] };
  countryList.value = data || [];
}
provide('countryList', () => countryList.value);

onMounted(() => {
  loadCountryList();
});

</script>

<style lang="less" scoped>
.error-container {
  background: #F7F8FA;
  min-height: 100%;
  :deep(.header-menu) {
    background-color: #fff;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 240px;
    .tip {
      font-weight: 500;
      font-size: 32px;
      line-height: 45px;
      color: #1D2129;
      margin: 0;
    }
    .img-404 {
      width: 380px;
      height: 163px;
      background: url('https://video.reta-inc.com/image/default/B99A12D7A28E4FBA862C7BCB956E2254-6-2.png') no-repeat center center/100% 100%;
      margin-top: 80px;
    }
    .back {
      background: url('@/assets/icons/back.svg') no-repeat left center/35px 35px;
      padding-left: 43px;
      margin-top: 90px;
      cursor: pointer;
      .text {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #165DFF;
      }
    }
  }
}
</style>
